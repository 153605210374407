a.circle-wrap {
  $circle-size: 120px;

  display: table;
  margin: 0 auto;
  margin-bottom: 25px;

  color: white;

  .round-button-circle {
    display: table-cell;

    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;

    text-align: center;
    font-family: Arial;
    font-size: 16px;
    vertical-align: middle;

    padding: 10px;

    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
  }
}

a.circle-wrap:hover {
  color: white !important;
}

.round-button-circle:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}

.red-circle {
  background: red !important;
}
.yellow-circle {
  background: yellow !important;
  color: black;
}
.green-circle {
  background: green !important;
}