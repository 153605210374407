@import "bower_components/tether/dist/css/tether.min";
@import "resources/assets/sass/variables.scss";
@import "resources/assets/sass/round-button.scss";
@import "bower_components/bootstrap/scss/bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min";
@import "bower_components/jt.timepicker/jquery.timepicker";
@import "public/css/lib/trumbowyg.min";
@import "public/css/lib/trumbowyg.colors.min";

.smooth
{
  transition: ease 0.3s;
  transition-property: all;
}
.row
{
    @include make-row();
}
.dashboard
{
  @include make-col-span(10);
  @include make-col-offset(1);
}
.schedules
{
  @include make-col-span(6);
  @include make-col-offset(3);
}
.warning-display
{
  @include make-col();
  @include make-col-span(12);
}
.cell-top
{
  vertical-align: top !important;
}
.slim
{
  @include make-col();
  @include make-col-span(6);
  padding: 0px;
}
#qm-average-score
{
  display: block;
}

#assigns-grid{
  margin-left: 30px;
}
.assigns-periods
{
  @include make-col();
  @include make-col-span(12);

  .pagination
  {
    margin-top: 0;
  }

  th.period-date
  {
    width: 300px;
  }

  .table {
    margin-top: 15px;
  }
}
.studies
{
  @include make-col-span(6);
  @include make-col-offset(3);
}
.password-change
{
  @include make-col();
  @include make-col-span(4);
  @include make-col-offset(4);
}
.field-notes-title {
  margin: 10px;
}
.project-notes {
  width: 75%;
  margin-left: 15%;
  margin-top: 30px;
}
.project-notes.page-title {
  margin: 5px 0 5px 0;
  font-weight: 500 !important;
}
.content-top
{
    @include make-col-span(12);
    min-height: 50px;
    text-align: center;
}
.customer-label
{
  width: 220px;
}
.text-right
{
  text-align: right;
}
.text-left
{
  text-align: left;
}
.text-center
{
  text-align: center;
}
.first-shift
{
  background-color: rgba(139, 195, 74, 0.33);
}
.approved {
  font-weight: 500;
  color: #43A047;
}
.denied {
  color: #CA3429;
  font-weight: 500;
}
.pending {
  color: #EF6C00;
  font-weight: 500;
}
/* ==========================================================================
   General
   ========================================================================== */

html,
body
{
  margin: 0;
  padding: 0;
  font-size: $font-size-root;
}

html
{
  position: relative;
  min-height: 100%;
}

body
{
  margin-bottom: 60px;
}

$primary-bg-color: #fff;
$secondary-bg-color: #003366;
$tertiary-bg-color: #202327;
$primary-txt-color: #333;
$secondary-button-color: #7A7A7A;

$button-padding: 0.30em 0.90em;
$button-border-radius: 4px;

select { height: 29px; }
input[type="date"] { padding: 0 5px; }

/* Page Title */
UL.pagination
{
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.page-title
{
  color: $brand-primary;
  margin-bottom: 0.50em;
}

/* Buttons */

@mixin button
{
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: $button-border-radius;
  padding: $button-padding;

  &:hover
  {
    text-decoration: none;
  }
}

/* Bootstrap Overrides */

.table-striped tbody tr:nth-of-type(even) {
    background: white;
}

.btn-primary
{
  @include button-variant(#fff, #42B8F8, #42B8F8)
}

.btn-primary-outline
{
  border-color: #42B8F8;
  color: #42B8F8;

  &:hover,
  &:focus,
  &:active
  {
    border-color: #42B8F8;
    background-color: #42B8F8;
  }
}

.btn-primary-outline {
  @include button-outline-variant(#42B8F8);
}

.btn-link
{
  color: #008ACA;

  &:hover
  {
    color: #1287BE;
  }
}

.form-control
{
  background-color: #E6F0F2;
  border-color: #E6F0F2;
}

.form-control-label
{
  color: #004E89;
  font-weight: bold;
  text-transform: uppercase;
}

/* Bootstrap container creation */

.header-container
{
  max-width: 1140px;
  @include make-container();
  margin: 0 auto;
}

/* Table settings */

.table th,
.table td
{
  vertical-align: middle;
}

/* Transform Mixin */

@mixin transform-by-90
{
  -webkit-transform: rotate(-90deg);
     -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

/* Advanced text editor */

.trumbowyg-editor,
.trumbowyg-textarea
{
  background-color: #fff;
}

/* ==========================================================================
   Site Header
   ========================================================================== */

.site-header
{
  position: relative;
  z-index: 2;
  box-shadow: inset 0 1px 0px rgba(51, 51, 51, 0.89);
  border-top: 1px solid #1357A5;
  .header-top
  {
    padding: 0.50em 0;

    .session-control
    {
      .button-session
      {
        float: right;
        margin-top: 0;
        background-color: #004B8D;
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 4px;
        padding: 5px 10px;
        font-weight: 300;
        letter-spacing: 1px;
      }
    }
  }

  .header-bottom
  {
    position: relative;
    z-index: 2;
    background-color: #003366;
    box-shadow: inset 0px 0px 1px rgb(255, 255, 255);
  }

  @media (max-width: 34em)
  {
    z-index: 4;
  }
}

.header-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 341px;
  height: 154px;
  background-image: url('/images/elemental-header-right.png');
  background-repeat: no-repeat;
  opacity: 0.3;

  @media (max-width: 34em)
  {
    width: 230px;
    height: 35px;
    background-size: cover;
  }
}

/**
 * Login page
 */

#login-page
{
  .site-logo
  {
    padding-bottom: 30px;
  }
}

/**
 * Main Navigation
 */

.menu
{
  list-style-type: none;
  margin: 0;
}

.menu-main
{
  padding: 0;

  .menu-item
  {
    float: left;
    font-size: 12px;

    a
    {
      display: inline-block;
      color: #fff;
      padding: 10px 2px;
      text-transform: capitalize;
      font-weight: 400;
      letter-spacing: 1px;
    }

    .fa
    {
      color: #fff;
      margin: 0 3px;
      font-size: 16px;
    }

    .fa.fa-plus, .fa.fa-minus
    {
      color: #DADADA;
      font-size: 12px;
    }

    .wrap-menu-sub
    {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;

      .menu-sub
      {
        display: none;
        width: 100%;
        margin: 0 auto;

        &.menu-level-1
        {

          a
          {
            display: inline-block;
            color: #E7F5EE;
            padding: 10px 10px 10px 20px;
            text-transform: capitalize;
            letter-spacing: 0.5px;
            font-family: sans-serif;
          }
        }

        &.menu-level-2
        {

          a
          {
            display: inline-block;
            color: white;
            padding: 10px 10px 10px 20px;
            text-transform: capitalize;
            letter-spacing: 0.5px;
            font-family: sans-serif;
          }
        }
      }

      &.wrap-level-1
      {
        background-color: #245B94;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.25);
      }

      &.wrap-level-2
      {
        background-color: #109403;
        box-shadow: inset 0px 0px 2px white;
      }
    }
  }
}

/* Override Bootstrap navbar position: relative to support
   extending submenus.
   Also remove padding too.
*/
.navbar
{
  position: static;
  padding: 0;
}
.navbar-toggle {
  background: #00AB50;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 2px;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
  outline: none;
}
.navbar-nav .nav-item + .nav-item {
    margin-left: 10px;
}

@media (max-width: 34em)
{
  .navbar
  {
    padding: 0.5em;
  }
  .navbar-nav .nav-item+.nav-item
  {
    margin-left: 0;
    display: block;
    width: 100%;
  }
  .expandable
  {
    position: relative;
  }

  .menu-main
  {
    .menu-item
    {
      .wrap-menu-sub
      {
        &.wrap-level-1,
        &.wrap-level-2
        {
          border-top: 0;
          z-index: 9999;
        }
      }
    }
  }

  .header-container
  {
    padding: 0
  }

  .site-header
  {
    .header-top
    {
      height: auto;
      padding: 0;

      div[class^='col-']
      {
        min-height: 0;
      }

      .session-control
      {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
        z-index: 3;

        .button-session
        {
          margin: 0;
        }
      }
    }
  }
}

/* ==========================================================================
   Main Content
   ========================================================================== */

.main-content
{
  position: relative;
  z-index: 1;
  padding: 1em 0;
}

@media (max-width: 34em)
{
  .main-content
  {
    z-index: 1;
  }
}

.moduletable .ja-box-ct .custom p span strong span {
  padding-left: 5px;
}

.logo {
  height: 75px;
  padding: 15px 0;
}

/**
 * Login Form
 */

#login-page
{
  .main-content
  {
    min-height: 1000px;
  }

  .header-bg
  {
    opacity: 1;
  }

  .footer-bg-left,
  .footer-bg-right
  {
    opacity: 1;
  }
}
#edci-home-page
{
  .main-content
  {
    //min-height: 800px;
  }

  .header-bg
  {
    opacity: 1;
  }

  .footer-bg-left,
  .footer-bg-right
  {
    opacity: 1;
  }
}

.sign-in
{
    margin-top: 2em;
    @include make-col();

    @media (min-width: 62em)
    {
      @include make-col-span(6);
      @include make-col-offset(6);
      @include make-col-pull(3);
    }

    @media (max-width: 61.9em)
    {
      @include make-col-span(10);
      @include make-col-offset(1);
      text-center: center;
    }

    .logo
    {
      margin-top: 10px;
      margin-bottom: 30px;
      margin-left: -15px;
    }

    form
    {
      ::-webkit-input-placeholder
      {
        color: transparent;
      }

      :-moz-placeholder
      {
        color: transparent;
      }

      ::-moz-placeholder
      {
        color: transparent;
      }

      :-ms-input-placeholder
      {
        color: transparent;
      }

      @media (max-width: 33.9em)
      {
        width: 100%;

        label
        {
          display: none;
        }

        ::-webkit-input-placeholder
        {
          color: #004E89;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }

        :-moz-placeholder
        {
          color: #004E89;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }

        ::-moz-placeholder
        {
          color: #004E89;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }

        :-ms-input-placeholder
        {
          color: #004E89;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
}

.form-title
{
  margin-bottom: 1.5rem;
  color: #6A6F73;

  @media (max-width: 34em)
  {
    text-align: center;
  }
}

.button-submit
{
  text-transform: uppercase;

  @media (max-width: 33.9em)
  {
    width: 100%;
  }
}

.button-helper
{
  margin-top: 0.5rem;

  @media (max-width: 33.9em)
  {
    display: block;
    text-align: center;
  }
}

/**
 * Validation Errors
 */

$error-color: #F01D59;

.display-error
{
  display: block;
  color: $error-color;
  border: 1px solid $error-color;
  border-radius: 0.15em;
  padding: 0.25em;
  margin: 0.25em 0;
  font-size: 0.85em;
}

/**
 * Statutory Holidays
 */

.statutory-holidays
{
  @include make-col();
  @include make-col-span(12);

  .actions
  {
    @include make-col();
    @include make-col-span(12);

    .filter
    {
      @include make-col();
      @include make-col-span(2);
    }

    .commands
    {
      @include make-col();
      @include make-col-span(10);
    }
  }
}

.statutory-holidays-filter {
  margin-bottom: 10px;
}


.statutory-holidays-form
{
  @include make-col();
  @include make-col-span(4);
}

/**
 * Reports
 */

.leave-reports
{
  @include make-col();
  @include make-col-span(12);
}
.warning-reports
{
  @include make-col();
  @include make-col-span(12);
}
.pay-period-reports
{
  @include make-col();
  @include make-col-span(12);
}
.attendance-reports
{
  @include make-col();
  @include make-col-span(12);
}
.period-descriptor
{
  text-align: center;
  font-weight: 600;
}

/**
 * Users Edit
 */
ul.pagination .page-link {
  padding: 3px 10px;
}
.table-sm th, .table-sm td {
    padding: 0.5rem 1rem;
}

.actions {
  select, input, a {
    height: 27px;
  }
  .btn {
    padding: 3px 10px;
  }

  .form-group {
    margin-bottom: 0px;
  }
}

.user-edit
{
  legend
  {
    font-size: 17px;
  }
}

.additional-notes {
  height: 71px;
}

.list-permissions
{
  padding: 0 1.3%;
  display: none;
  .select-all-permissions {
    float: right;
  }
  .form-group .col-md-4 {

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    input[type="checkbox"] {
      float: right;
    }
    margin: 10px 0;
  }
}

.permission-selection-controls {
  float: right;
  .hide {
    display: none;
  }
}

.flaot-left {
  float: left;
}

@media screen and (max-width: 768px) {
  .list-permissions {
    padding: 0 6% !important;
  }
}
.dayshift-scheduling {
  margin-top: 33px;
}
.aboriginal-status {
  padding: 25px 0 0 15px;
}
.hire-button {
    float: right;
    background: #2196F3;
}

.permission-title
{
  .select-all,
  .deselect-all
  {
    font-size: 0.65em;
  }
}

/**
 * User Manager
 */


.users
{
  .user-manager
  {
    @include make-col();
    @include make-col-span(12);
  }

  .actions
  {

    @include make-col();
    @include make-col-span(12);
  }

}

.user-view-model {
  height: 100%;
  overflow: scroll;
}

.user-manager.view {
  padding: 3% 8% !important;
}

.user-manager {
  margin: 0 5%;
  padding: 1% 3%;
  background: white;
  border: 1px solid #E6F0F2;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  .page-title {
    margin: 10px 0 15px 0;
  }
  .form-group.hide {
    display: none;
  }
  label {
    margin-bottom: 0 !important;
  }
  input, select, textarea {
    margin-bottom: 5px !important;
    margin-top: 2px;
    border-radius: 2px;
  }
  .termination-details {
   display: none;
  }
}


/**
 * Employee Incidents
 */

.employee-incidents
{
  @include make-col();
  @include make-col-span(12);
}

.employee-incident-form
{
  @include make-col();
  @include make-col-span(6);
  width: 100%;
}

.notes-label {
  margin: 0 0 0px 10px;
}

.current-time
{
  margin-top: 0.50em;
}
.employee-search {
  margin-bottom: 20px;
}

.warning-end-time, .warning-start-time {
  margin: 5px 15px 0px 12px;
  padding: 5px 8px;
}

/**
 * Payroll Periods
 */

.payroll-period-form
{
  @include make-col();
  @include make-col-span(4);
}
.payroll-periods
{
  @include make-col-span(8);
  @include make-col-offset(2);
}

.periods-main
{
  @include make-col();
  @include make-col-span(12);
}
.periods-main.form-group {
  margin-bototm: 10px;
}

/**
 * Schedule Periods
 */

.scheduling-periods
{
  @include make-col();
  @include make-col-span(12);
  .page-title {
    float: left;
    margin-top: 5px;
  }
}
.scheduling-period-shifts
{
  @include make-col();
  @include make-col-span(12);
}

.schift-edit
{
  @include make-col();
  @include make-col-span(4);
}
.over-hours {
  background: rgba(236, 62, 49, 0.7);
  color: white;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.61);
}
.under-hours {
  background: rgba(76, 175, 80, 0);
  color: black;
}
.warning-hours {
  background: rgba(255, 152, 0, 0.25);
  color: black;
}
.schedule-study-modal {
  label {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin-top: 10px;
  }
  .btn {
    margin-top: 25px;
  }
}

/**
 * User Shifts
 */

.user-shifts
{
  @include make-col();
  @include make-col-span(12);
}

.shifts-list
{
  @include make-col();
  @include make-col-span(12);

  @media (max-width: 34em)
  {
    width: 100%;
  }
  .alert-info {
    margin-bottom: 0;
    padding: 10px;
  }
}

.user-shifts-tabs
{

}
.user-shifts table {
  border: 1px solid #DDDDDD;
}
/**
 * Period Shift Edit
 */

.shift-edit
{
  @include make-col();
  @include make-col-span(12);
}

/**
 * User timezone section
 */
.user-timezone {
  padding-left: 0.9375rem;
  padding-bottom: 0.5rem;
  select#timezone {
    width: 50%;
    margin-bottom: 1rem;
  }
  .timezone-notice {
    display: none;
    background-color: #eee;
  }
}

/**
 * Schedule Management
 */

#schedule-management-page
{
  .site-header
  {
    display: none;
  }
}

.schedule-container
{
  max-width: 100%;
  @include make-container();
}

.new-user
{
  background-color: rgba(76, 175, 80, 0.5);
}

.on-vacation
{
  background-color: rgba(255, 193, 7, 0.25);
}

.record-control-icon
{
  font-size: 18px;
}
.record-control
{
  width: 35px;
}
.manage
{
  @include make-col();
  @include make-col-span(12);
  padding-left: 3em;

  .table th,
  .table td
  {
    padding: 0.25rem;
  }

  .management-table
  {
    table-layout: fixed;
    font-size: 0.95em;

    .main-table-header
    {
      overflow-y: scroll;
    }

    .main-table-body
    {
      height: 560px;
      overflow-y: scroll;
    }

    .main-table-header,
    .main-table-body
    {
      display: block;
    }

    .actions
    {
      position: relative;
      width: 45%;

      .btn
      {
        margin: 0.25em 0;
      }

      .hide-columns
      {
        position: absolute;
        z-index: 1;
        top: 100%;
        right: -6px;
        font-size: 10px;
        color: #fff;
        border: 1px solid #c9302c;
        background-color: #c9302c;
        padding: 0.1em 0.5em;
        top: 9px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        width: 34px;
        text-align: center;
        padding: 0;

        &:hover,
        &:focus,
        &:active
        {
          text-decoration: none;
        }
      }

      &.narrow
      {
        width: 12%;
      }
    }

    .navigate
    {
      width: 40%;

      .btn
      {
        margin: 0.25em 0;
      }

      .previous
      {
        float: right;
        margin-right: 5px;
      }

      .next
      {
        float: right;
      }

      .snapshot
      {
        float: left;
        margin-left: 0.50em;
      }

      .toggle-summary
      {
        float: left;
        margin-left: 0.35em;
      }

      &.wide
      {
        width: 60%;
      }
    }

    .total-work
    {
      width: 3%;
      min-width: 40px;
      font-size: 0.85em;
      text-align: center;
    }

    .emp-info
    {

      width: 180px;
      position: relative;

    }

    .column-status
    {

      width: 300px;

      .name-first
      {
        overflow-x: auto;
        white-space: nowrap;

      }

      .name-last
      {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .emp-id
      {
        width: 50px;
        max-width: 50px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }

    .column-release
    {
      border-left: 0px;
      border-right: 0px;
      overflow-x: auto;
      white-space: nowrap;

      .emp-eng
      {
        overflow-x: auto;
        white-space: nowrap;
      }

      .emp-fr
      {
        overflow-x: auto;
        white-space: nowrap;
      }

      .emp-other
      {
        overflow-x: auto;
        white-space: nowrap;
      }
    }

    .column-scores
    {
      overflow-x: auto;
      white-space: nowrap;
      border-left: none;

      .attendance
      {
        overflow-x: auto;
        white-space: nowrap;
      }

      .quality
      {
        overflow-x: auto;
        white-space: nowrap;
      }

      .performance
      {
        overflow-x: auto;
        white-space: nowrap;
      }
    }

    .column-release
    {
      width: 70px;
    }

    .column-scores
    {
      width: 130px;
    }

    .scroll-wrap
    {
      width: 700px;
      overflow-x: hidden;

      &.wide
      {
        width: 1190px;

        .scrollable
        {
          width: 1190px;
        }

        .date-container
        {
          width: 170px;
        }

        .column-day,
        .column-night
        {
          width: 85px;
          overflow: auto;
          white-space: normal;
        }
        .column-weekend
        {
          width: 170px;
          overflow: auto;
          white-space: normal;
        }
      }
    }

    .scrollable
    {
      position: relative;
      width: 1225px;
      table-layout: fixed;
    }

    .date-container
    {
      width: 175px;
      text-align: center;
      white-space: nowrap;
      padding-left: 0;
      padding-right: 0;
      border-top: 0;
      border-bottom: 0;
      box-sizing: border-box;
    }

    .column-day,
    .column-night,
    .column-weekend
    {
        width: 87.5px;
        text-align: center;
        white-space: nowrap;
        box-sizing: border-box;
        font-size: 0.85em;

        .time-entry
        {
          display: inline-block;
          min-width: 50px;
          min-height: 10px;
        }

        .hide-summary
        {
          display: none;
        }
    }

    .inline
    {
      width: 100%;
      table-layout: fixed;

      th,
      td
      {
        border-top: 0;
        border-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }

      th:first-child,
      td:first-child
      {
        border-left: 0;
      }

      th:last-child,
      td:last-child
      {
        /* border-right: 0; */
      }
    }

    th,
    td
    {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

/* These are used by the filters on the schedule page and the shift assigns page */
.hide-user
{
  display: none;
}

.show-user
{
  display: table-row;
}

.entries-submission-result
{
  position: absolute;
  top: 9px;
  right: 23px;
  .entries-success,
  .entries-danger,
  .entries-info
  {
    padding: 6px 10px !important;
    display: none;
    padding: 0.65em;
  }
}

.target-input-aps
{
  width: 50px;
  font-size: 12px;
  background-color: #d8eed8;
}
/**
 * Customers Page
 */
.customers-search-button {
  flaot: left !important;
}
#submit-customer-contacts  {
  padding: 3px 10px;
  font-size: 12px;
}
.customers-control {
  float: right;
  margin: -7px 15px 5px;
}
.add-a-customer {
  float: right;
  margin: -7px 0px 5px;
}
.customer-contacts-title {
  margin: 10px 0 15px 0px;
}
.customer-contact-table {
  padding-left: 0px !important;
}
.customer-contact-table table {
  width: 100%;
}
.customer-contact-table .table-title {
  font-weight: bold;
  padding-left: 15px;
}
.customer-contact-table table tr {
  line-height: 30px;
}
.customers-auto-search .form-group {
  margin-bottom: 5px;
}
.customers-search
{
  height: 28px;
  margin-bottom: 10px;
  margin-left: 0px;

  #search-customers {
  }
  #submit-customers {
    height: 27px;
    padding: 3px 5px;
    float: left;
  }
}
.customer-title {
  padding-left: 0px !important;
}
.customer-info-table {
  width: 100%;
}
.customer-info-table tr {
  line-height: 30px;
}
.customer-table-title {
  font-weight: bold;
}
.update-contact-btn {
  margin-top: 15px;
}
.add-contact-title {
  padding-left: 15px;
}
.add-contact-button {
  padding-right: 25px;
}
.no-padding {
  padding: 0;
}
.update-contact-button {
  margin-top: 10px !important;
}

.customers-control
{
  float: right;
}

.customers
{
  @include make-col();
  @include make-col-span(12);
  padding-left: 0px;
}

.customers-info-primary
{
  @include make-col();
  @media (min-width: 62em)
  {
    @include make-col-span(4);
  }

  @media (max-width: 61.9em)
  {
    @include make-col-span(12);
  }
}

.customers-info-additional
{
  @include make-col();
  @media (min-width: 62em)
  {
    @include make-col-span(4);
  }

  @media (max-width: 61.9em)
  {
    @include make-col-span(12);
  }
}

.system-bulletin
{
  @include make-col();
  @include make-col-span(12);
}

.station-message
{
  @include make-col();
  @include make-col-span(8);
  @include make-col-offset(2);
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 300px;
  overflow-y: scroll;
}
.station-message-heading
{
  @include make-col();
  @include make-col-span(8);
  @include make-col-offset(2);
  background-color: rgba(255, 255, 255, 0.8);
  H1
  {
    font-size: 24px;
  }
}

.voxco-link
{
  margin-right: 12px;
  display: inline;
}

.edci-home-logo
{
  margin-right: 50px;
}

.dimensions-link
{
  display: inline;
}

.pre-buffer
{
  background-color: #00b3ee;
  height: 2px;
  margin-bottom: 10px;
}

.buffer
{
  height: 30px;
}

/**
 * Autocomplete
 */

.autocomplete-suggestions
{
  background-color: #fff;
  padding: 0px;
}

.autocomplete-suggestion
{
  padding: 4px 7px;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  cursor: pointer;
}
.autocomplete-selected {
  background-color: #42B8F8;
  color: white;
}
/**
 * Table sort indicator
 */

th.header
{
  cursor: pointer;
}
.open-user-popup
{
  cursor: pointer;
}
/**
 * Customer Contacts Page
 */

.customer-contacts-section
{

}

.autocomplete-results
{
  position: relative;

  .list-results
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    .result
    {
      cursor: pointer;
      background-color: #E6F0F2;
      padding: 0.25em 0.85em;

      &:hover
      {
        background-color: $brand-success;
        color: #fff;
      }
    }
  }
}

/**
 * Modal Window
 */

#overlay
{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #212121;
  opacity: 0.5;
  z-index: 9998;
}

#modal-window
{
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 3px;
  padding: 1em;
  z-index: 9999;
  overflow: scroll;
}

#modal-content
{
  padding: 0.25em;
  width: 90%;
  margin: 0 auto;
}

#close-modal
{
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 15px;
  padding: 1px 7px;
  color: #868686;
  border-radius: 3px;
  width: 25px;
  height: 25px;
  border: 1px solid #d4d4d4;

  &:hover,
  &:focus,
  &:active
  {
    color: white;
    background: #c2c2c2;
    text-decoration: none;
  }
}

/**
 * Time Entry Status Modal Content
 */

#form-status
{
  .warning
  {
    display: none;
  }
}

/**
 * Snapshot Modal Content
 */

#section-snapshot
{
  display: none;
}

#snapshot-content
{
  /*height: 350px;
  overflow-y: auto;*/
  h1 {
    margin-bottom: 15px;
  }
  .difference-positive
  {
    background-color: $brand-success;
    color: #fff;
  }

  .difference-negative
  {
    background-color: $brand-danger;
    color: #fff;
  }
}

/**
 * Filter Table
 */

#mass-update-button
{
  position: fixed;
  z-index: 9998;
  top: 5%;
  left: 0px;
  height: 18px;
  padding-left: 8px;
}
.filter-table
{
  position: fixed;
  z-index: 9999;
  top: 10%;
  left: 0;
  font-size: 12px;
  height: 500px;
  background-color: #fff;
  padding: 0px 0px 0px;
  max-width: 350px;
  .form-group
  {
    margin-bottom: 1em;
  }

  .form-control
  {
    font-size: 1em;
  }

  .filter-panel
  {
    position: relative;
    float: left;
    width: 35px;
    height: 100%;
    background-color: $brand-info;
    color: #fff;
    border-radius: 0 3px 3px 0;
    overflow: hidden;

    .panel-label
    {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: -57px;
      white-space: nowrap;
      margin: 0;
      @include transform-by-90;

      .filter-display
      {
        color: #fff;

        &:hover,
        &:active,
        &:focus
        {
          text-decoration: none;
        }
      }
    }
  }

  #filter-reset
  {
    position: absolute;
    font-size: 10px;
    left: 0;
    right: 0;
    bottom: -17px;
    display: inline-block;
    color: #fff;
    height: 20px;
    width: 35px;
    padding: 0.20em;
    text-align: center;
    border-radius: 0 0 3px 0;
    overflow: hidden;
    background-color: #C9302C;
    border-bottom: 1px solid #7F0A07;
    border-right: 1px solid rgba(181, 21, 16, 0.99);
    padding-top: 4px;

    &:hover,
    &:focus,
    &:active
    {
      text-decoration: none;
      color: white;
    }
  }

  #filter-reset-assigns
  {
    position: absolute;
    font-size: 10px;
    left: 0;
    right: 0;
    bottom: -17px;
    display: inline-block;
    color: #fff;
    height: 20px;
    width: 35px;
    padding: 0.20em;
    text-align: center;
    border-radius: 0 0 3px 0;
    overflow: hidden;
    background-color: #C9302C;
    border-bottom: 1px solid #7F0A07;
    border-right: 1px solid rgba(181, 21, 16, 0.99);
    padding-top: 4px;

    &:hover,
    &:focus,
    &:active
    {
      text-decoration: none;
      color: white;
    }
  }

  .filter-options
  {
    display: none;
    float: left;
    width: 89%;
    overflow: scroll;
    border-top: 2px solid #5BC0DE;
    border-right: 2px solid #5BC0DE;
    border-bottom: 2px solid #42B8F8;
    height: 100%;
    padding: 20px;
  }
}

/**
 * Leave Requests
 */

.leave-requests
{
  @include make-col();
  @include make-col-span(12);
  .page-title {
    float: left;
    margin-top: 5px;
  }
  th, tr {
    text-align: center;
  }
}
.leave-request-additional-notes {
  height: 171px;
  margin-top: 21px;
}

.leave-request-title {
  margin-bottom: 12px;
}

.leave-requests-form
{
  @media (max-width: 34em)
  {
    width: 100%;
  }

  label {
    font-weight: bold;
  }

  .data {

  }

  .trumbowyg-box {
    margin-top: 0;
    border-radius: 3px;
  }

  .status-notes-field
  {
    display: block !important;
    .trumbowyg {
      margin: 2px 0;
      width: 100%;
    }
  }
}
.reduced-hours-view-form {
  padding: 10px;
  background: white;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  min-height: 376px;
}
.reduced-hours-additional-info {
  height: 171px;
  margin-top: 21px;
}
.leave-request-status {
  text-transform: capitalize;
}
.reduced-hours-request-status {
  margin: 0 -1px 0px -13px;
  background: white;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 10px 0 10px;
}
.reduced-hours-request-info {
  text-align: center;
  padding: 10px;
}
#submit-vacation-reduced-hours {
  height: 27px;
  padding: 3px 5px;
  float: left;
}
.payroll-table-header {
  margin-bottom: 10px !important;
}
.reduced-hours-request-reasons {
  .col-sm-6:first-child {
    padding-left: 0;
  }
  .col-sm-6:last-child {
    padding-right: 0;
  }
}

.reduced-hours-container {
  padding-left: 0px;
  padding-right: 0px;
}

/**
 * Projects
 */

.projects
{
  @include make-col;
  @include make-col-span(12);

  .form-group label { margin-right: 10px; }

  #submit-projects-search {
    height: 27px;
    padding: 2px 10px;
  }
}

.projects-show
{
  @include make-col;
  @include make-col-span(6);
}

.view-project table {
  background: white;
}

label.project-form-radio-label {
  font-weight: 500;
  width: 100%;
}
.new-project-title {
  margin: 10px 0 20px;
}
.new-project-buttons {
  margin: 0px -12px 15px -12px !important;
}

.project-view-result {
  font-weight: 500;
}
#project-modal {
  .alert {
    padding: 5px 10px;
  }
}
/**
 * Reconciliations
 */
.reconciliations
{
  @include make-col();
  @include make-col-span(6);
  @include make-col-offset(3);
}
.project-label
{
  font-size: 1.15em;
  font-weight: 600;
}

/**
 * Reconciliations
 */
.reconciliations {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 50%;
  margin-left: 25%; }

.project-label {
  font-size: 1.15em;
  font-weight: 600; }

.supervisors-not-saved {
  display: block;
  font-size: 12px;
  padding: 5px 10px;
  text-align: center;
}


/**
 * Shift Assignments
 */
// The following is to support Angular ngCloak for the shift assignments table
blink, .blink {
  -webkit-animation: blink 1s step-end infinite;
  -moz-animation: blink 1s step-end infinite;
  -o-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}
@-webkit-keyframes blink {
  67% { opacity: 0 }
}

@-moz-keyframes blink {
  67% { opacity: 0 }
}

@-o-keyframes blink {
  67% { opacity: 0 }
}

@keyframes blink {
  67% { opacity: 0 }
}
.notifications-icon
{
  font-size: floor($font-size-base * 2);
  color: $brand-danger;
  cursor: pointer;
}
.notification-item.list-group-item
{
  cursor: pointer;
}
.discrepancy
{
  TD.column-time-on
  {
    background-color: $brand-danger;
    color: white;
  }
}
.fixed-width
{
  //font-family: $font-family-monospace;
  //font-weight: 600;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak
{
  display: none !important;
}
table#shift-assignments-grid
{
  width: 100%;
}
.manage-quality-monitor
{
  cursor: pointer;
}
#time-range-error
{
  display: none;
}
#shift-selection-error
{
  display: none;
}
.column-bg
{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.breaks-configured
{
  background-color: lighten($brand-info, 30%);
}
.passed
{
  background-color: $alert-success-bg;
  color: $alert-success-text;
}
.failed
{
  background-color: $alert-danger-bg;
  color: $alert-danger-text;
}
#break-validation-error
{
  display: none;
}
.btn-disabled
{
  pointer-events: none;
  opacity: 0.7;
}
#qm-modal-container
{
  height: 580px;
  position: relative;

  #qm-modal-content
  {
    padding: 30px;
    height: 579px;
    overflow-y: auto;
    position: relative;
  }
}
#snapshot-modal-container
{
  height: 280px;
  position: relative;

  #snapshot-modal-content
  {
    padding: 15px;
    height: 279px;
    overflow-y: auto;
    position: relative;
  }
}
#interviewer-name
{
  font-weight: 600;
  font-size: 1.05em;
  display: block;
}
.shift-actions .btn {
  min-width: 40px;
}
.ui-timepicker-wrapper {
  width: 150px !important;
}
.regular-hours
{
  width: 60px;
  min-width: 60px;
  display: inline;
  padding: 6px 12px 6px 12px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: lighten($brand-success, 35%);
}
.training-hours
{
  width: 60px;
  min-width: 60px;
  display: inline;
  padding: 6px 12px 6px 12px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: lighten($brand-info, 25%);
}
.user-language
{
  width: 20px;
  padding: 1px 2px 1px 2px;
  display: inline;
  margin-right: 2px;
  margin-lefth: 2px;
}
.project-studies {
  margin-bottom: 10px;
  padding: 0 14px;
}
.add-employee-header {
  margin-bottom: 20px !important;
}
.alert-no-user-selected, .alert-no-starts-at, .alert-no-ends-at {
  display: block;
  padding: 5px 11px;
}
.snapshot-modal-title {
  margin-bottom: 15px;
}
.shift-assignments
{
  @include make-col();
  @include make-col-span(12);

  .column-period-study
  {
    text-align: center;
  }

  .column-shifts {
    width: 40px;
  }

  .column-project-study
  {

  }
  .table-employees tr {
    background: white;
  }
  .column-notes {
    a {
      cursor: pointer;
    }
  }
  .table-shift-assignments
  {
    width: 100%;
    height: 145px;

    .column-shifts
    {
      width: auto;
    }

    .table-employees td
    {
      height: 60px;
      max-height: 60px;
    }

    .column-projects
    {
      width: 22%;
      vertical-align: top;

      .projects-wrapper
      {
        position: relative;
        width: 100%;
        overflow: hidden;

        .prev-study,
        .next-study
        {
          position: absolute;
          z-index: 2;
          display: block;
          width: 15px;
          height: 100%;
          border: 1px solid #ccc;

          &:hover
          {
            background-color: #ccc;
          }

          .fa
          {
            position: absolute;
            top: 45%;
            vertical-align: middle;
          }
        }

        .prev-study
        {
          left: 5px;
        }

        .next-study
        {
          right: 5px;
        }

        .table-controls {
          margin: auto;
        }
      }

      &.wide .table-controls {
        width: 100%;
      }

      .scrollable
      {
        position: relative;
        width: 1000px;
      }

      .column-study
      {

        .study-time
        {
          display: block;
        }

        .project-name
        {
          display: block;
        }

        .study-id
        {
          display: block;
        }

        .study-target-rate
        {
          display: block;
        }

        .study-bid-rate
        {
          display: block;

          .bid-rate
          {
            width: 30px;
          }
        }
      }

      .info-container
      {
        padding: 0.50em 1.50em;
        text-align: center;
        margin: auto;
      }

    }
  }

  .shift-actions
  {
    padding: 0.50em 0;
  }

  .shift-date
  {
    margin-top: 0.50em;
  }

  .shift-status
  {
    margin-top: 0.50em;
  }

  .employee-snapshot
  {
    margin-top: 0.50em;
  }

  .employee-project-focus
  {
    margin-top: 1em;
  }

  .toggle-table-section
  {
    margin-top: 1em;

    .fa-arrow-right.hidden
    {
      display: none;
    }

    .fa-arrow-left.hidden
    {
      display: none;
    }

  }
}

.study-project-selector {
  width: 144px;
}

.add-a-study-title {
  margin-bottom: 25px;
}

.study-project-selector-dropdown {
 width: 100%;
}

.warning-studies {
  display: none;
}

.warning-display .table td {
    vertical-align: top;
}

/**
 * Editable Cells
 */

td.current-cell
{
  background-color: lighten($brand-primary, 25%);
  font-weight: 600;
  color: #FFFFFF;
  .regular-hours, .training-hours
  {
    color: #999999;
  }
}
INPUT.booth-input, INPUT.paid-hours-input
{
  width: 60px;
  display: none;
  border: 0;
  background-color: lighten($brand-primary, 23%);
  color: #FFFFFF;
}
INPUT.training-hours-input
{
  width: 50px;
  border: 0;
  background-color: lighten($brand-info, 25%);
  color: black;
}
INPUT.regular-hours-input
{
  width: 50px;
  border: 0;
  background-color: lighten($brand-success, 35%);
  color: black;
}
INPUT.target-rate
{
  width: 55px;
  border: 0;
  background-color: #EFEFEF;
}
.nav-pager-disabled
{
  color: #EDEDED;
}

/**
 * Shift Assignments
 */

/* Add study modal window */

.alert-incomplete-fields
{
  display: none;
}

/* ==========================================================================
   Site Footer
   ========================================================================== */

.site-footer
{
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 40px;
  background-color: $tertiary-bg-color;
  padding: 0.55em 0;

  .footer-bottom
  {
    position: relative;
    z-index: 2;
  }

  .copyright
  {
    color: #fff;
  }

  .footer-bg-left
  {
    position: absolute;
    bottom: 40px;
    left: 0;
    background-image: url('/images/elemental-footer-left.png');
    background-repeat: no-repeat;
    opacity: 0.3;

    @media (min-width: 75em)
    {
      width: 632px;
      height: 534px;
    }

    @media (max-width: 74.9em)
    {
      width: 400px;
      height: 300px;
      background-size: cover;
    }

   @media (max-width: 61.9em)
    {
      width: 300px;
      height: 200px;
      background-size: cover;
    }

    @media (max-width: 33.9em)
    {
      width: 300px;
      height: 200px;
      background-size: cover;
    }
  }

  .footer-bg-right
  {
    position: absolute;
    bottom: 40px;
    right: 0;
    width: 669px;
    height: 354px;
    background-image: url('/images/elemental-footer-right.png');
    background-repeat: no-repeat;
    opacity: 0.3;

    @media (min-width: 75em)
    {
      width: 669px;
      height: 354px;
    }

    @media (max-width: 74.9em)
    {
      width: 400px;
      height: 300px;
      background-size: cover;
    }

    @media (max-width: 61.9em)
    {
      width: 300px;
      height: 200px;
      background-size: cover;
    }

    @media (max-width: 33.9em)
    {
      width: 300px;
      height: 200px;
      background-size: cover;
    }
  }
}

/* ==========================================================================
   Start Page
   ========================================================================== */
.start-page {
  font-family: 'Roboto', sans-serif;
  a {
    text-decoration: none;
  }
  .container {
    max-width: 740px;
  }
  .col-list {
    float: left;
    width: calc(67% - 10px);
    margin: 0px 10px 0px 0px;
    th{
      text-transform: uppercase;
      font-weight: bold;
    }
    td {
      text-align: center;
    }
    td:first-child {
      text-align: left;
    }
    tr:nth-child(even) td{background: #f9f9f9}
    tr:nth-child(odd) td{background: #FFF}
  }
  .card {
    overflow: hidden;
    background-color: #edf4f7;
    border: 1px solid #e0eaed;
    padding: 30px 0;
    .img {
      float: left;
      width: 25%;
      text-align: center;
      border-right: 2px solid #cfd5d7;
    }
    .card-content{
      float: right;
      width: 75%;
      padding: 0 50px;
      h1 {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        &:before {
          content: "// ";
        }
      }
    }
  }
  .col-controls {
    float: right;
    width: calc(33% - 10px);
    margin: 0px 0px 0px 10px;
    .briefing-call-super {
      a {
        display: block;
        background-color: #26a1cd;
        text-align: center;
        text-decoration: none;
        color: #ffffff;
        text-transform: UPPERCASE;
        font-weight: bold;
        padding: 20px 0px;
        letter-spacing: 2px;
        font-size: 15px;
        @include border-radius(5px);
      }
    }
    .returns a {
      display: block;
      position: relative;
      padding: 10px 20px;
      margin-top: 5px;
      color: #ffffff;
      background-color: #939191;
      text-transform: UPPERCASE;
      font-size: 13px;
      text-decoration: none;
      @include border-radius(5px);
    }
    #break-on-off {
      display: block;
      position: relative;
      padding: 10px 20px;
      margin-top: 5px;
      color: #ffffff;
      text-transform: UPPERCASE;
      font-size: 13px;
      text-decoration: none;
      @include border-radius(5px);
      .toggle {
        position: absolute;
        width: 40px;
        height: 21px;
        right: 20px;
        top: 9px;
        @include border-radius(15px);
        &:before {
          position: absolute;
          content: "";
          height: 15px;
          width: 15px;
          left: 4px;
          bottom: 3px;
          @include border-radius(50%);
          -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
          -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
          box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
          -webkit-transition: .4s;
          transition: .4s;
        }
      }
      &.on {
        background-color: #71b56a;
        .toggle {
          background-color: #59935c;
          &:before {
            background-color: #71b56a;
            -webkit-transform: translateX(17px);
            -ms-transform: translateX(17px);
            transform: translateX(17px);
          }
        }
      }
      &.off {
        background-color: #c65252;
        .toggle {
          background-color: #9c4347;
          &:before {
            background-color: #c65252;
          }
        }
      }
    }
  }
}

.shift-information-modal {
  .shift-information-container {
    ul.question {
      padding-top: 10px;
      li {
        padding-top: 5px;
        list-style: decimal;
        table.question1-table {
          width: 90%;
          tr {
            line-height: 2em;
            border-bottom: 1px solid #ccc;
            td {
              vertical-align: top;
              padding-top: 10px;
            }
            td {
              label {
                padding-right: 5px;
              }
            }
            td.question-text {
              padding-right: 10px;
            }
          }
        }
        ul li {
          list-style: initial;
        }
      }
      div .form-check-label {
        padding-top: 10px;
        padding-right: 10px;
      }
      div .form-check-label.vaccination-status {
        padding-top: 0;
      }
      div.error {
        font-style: italic;
        color: red;
        padding: 10px 0;
        display: none;
      }
    }
    .tc {
      padding-top: 10px;
    }
    .shift-detail-container {
      .shift-detail-intro {
        padding: 15px 0;
      }
      ul li {
        list-style: none;
        padding: 5px 0;
      }
      .shift-detail-note {
        padding: 10px 0;
      }
    }
    div.vaccination-status-container {
      .vaccination-status-header {
        padding-bottom: 5px;
      }
      div.vaccination-status-error {
        font-style: italic;
        color: red;
        padding: 10px 0;
        display: none;
      }
      padding-top: 5px;
      margin-top: 10px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
    }
  }
  .buttons.hidden {
    display: none;
  }
  @media (max-width: 768px) {
    width: auto !important;
  }
}
